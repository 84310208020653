<template>
  <div class="home">
    <Support :appName="appName" :appLogo="appLogo" :appTitle="appTitle" />
  </div>
</template>

<script>
// @ is an alias to /src
import Support from '@/components/Support.vue'

export default {
  props:['appName', 'appLogo', 'appTitle'],
  name: 'SupportView',
  components: {
    Support
  }
}
</script>
