<template>
  <Wrapper :appName="appName" :appLogo="appLogo" :appTitle="appTitle" :title="'Регистрация'">
    <div class="anim__balls mb-5">
      <div
        class="anim__ball"
        :class="e1 >= 1 ? 'anim__ball_completed' : ''"
      ></div>
      <div
        class="anim__ball"
        :class="e1 >= 2 ? 'anim__ball_completed' : ''"
      ></div>
      <div
        class="anim__ball"
        :class="e1 >= 3 ? 'anim__ball_completed' : ''"
      ></div>
      <div
        class="anim__ball"
        :class="isCompleted ? 'anim__ball_completed' : ''"
      ></div>
    </div>

    <div class="auth__transition">
      <div v-if="e1 === 1">
        <!-- surname -->
        <div
          class="auth__input rounded auth__transition"
          :class="[
            !fio_f && firstBlured.find((item) => item === 'fio_f')
              ? 'auth__error_border mb-8'
              : 'mb-5',
          ]"
        >
          <label
            class="auth__error_text"
            v-if="!fio_f && firstBlured.find((item) => item === 'fio_f')"
            >Укажите фамилию</label
          >
          <input
            @change="setBlured('fio_f')"
            v-model="fio_f"
            name="fio_f"
            placeholder="Введите фамилию"
          />
        </div>
        <!-- surname -->
        <!-- name -->
        <div
          class="auth__input rounded auth__transition"
          :class="[
            !fio_i && firstBlured.find((item) => item === 'fio_i')
              ? 'auth__error_border mb-8'
              : 'mb-5',
          ]"
        >
          <label
            class="auth__error_text"
            v-if="!fio_i && firstBlured.find((item) => item === 'fio_i')"
            >Укажите имя</label
          >
          <input
            @change="setBlured('fio_i')"
            v-model="fio_i"
            name="fio_i"
            placeholder="Введите имя"
          />
        </div>
        <!-- name -->
        <!-- thirdName -->
        <div
          class="auth__input rounded auth__transition"
          :class="[
            !fio_o && firstBlured.find((item) => item === 'fio_o')
              ? 'auth__error_border mb-8'
              : 'mb-5',
          ]"
        >
          <label
            class="auth__error_text"
            v-if="!fio_o && firstBlured.find((item) => item === 'fio_o')"
            >Укажите отчество</label
          >
          <input
            @change="setBlured('fio_o')"
            v-model="fio_o"
            name="fio_o"
            placeholder="Введите отчество"
          />
        </div>
        <!-- thirdName -->
        <!-- email -->
        <div
          class="auth__input rounded auth__transition"
          :class="[
            (!email || !EMAIL_REGEXP.test(email)) &&
            firstBlured.find((item) => item === 'email')
              ? 'auth__error_border mb-10'
              : 'mb-5',
          ]"
        >
          <label
            class="auth__error_text auth_error_email"
            v-if="
              (!email || !EMAIL_REGEXP.test(email)) &&
              firstBlured.find((item) => item === 'email')
            "
            >{{
              !email
                ? "Укажите электронную почту"
                : "Укажите корректный адрес почты"
            }}
          </label>
          <input
            @change="setBlured('email')"
            v-model="email"
            name="email"
            placeholder="Введите эл. почту"
          />
        </div>
        <!-- email -->
        <!-- password -->
        <div
          class="auth__input rounded auth__transition"
          :class="[
            validatePassword(password) &&
            firstBlured.find((item) => item === 'password')
              ? 'auth__error_border mb-8'
              : 'mb-5',
          ]"
        >
          <label
            class="auth__error_text"
            v-if="
              validatePassword(password) &&
              firstBlured.find((item) => item === 'password')
            "
            >{{ validatePassword(password) }}</label
          >
          <div class="auth__two_params">
            <input
              @change="setBlured('password')"
              v-model="password"
              name="password"
              v-if="!show1"
              placeholder="Пароль"
              type="password"
            />
            <input
              @change="setBlured('password')"
              v-model="password"
              name="password"
              v-else
              placeholder="Пароль"
              type="text"
            />
            <button v-on:click="switchVisibleIcon">
              <v-icon>{{ show1 ? "mdi-eye" : "mdi-eye-off" }}</v-icon>
            </button>
          </div>
        </div>
        <div
          class="auth__input rounded auth__transition"
          :class="[
            (!password2 || rules.passequal(password2).length > 0) &&
            firstBlured.find((item) => item === 'password2')
              ? 'auth__error_border mb-8'
              : 'mb-5',
          ]"
        >
          <label
            class="auth__error_text"
            v-if="
              (!password2 || rules.passequal(password2).length > 0) &&
              firstBlured.find((item) => item === 'password2')
            "
            >Укажите повторно пароль</label
          >
          <div class="auth__two_params">
            <input
              v-if="!show2"
              @change="setBlured('password2')"
              v-model="password2"
              name="password2"
              placeholder="Пароль"
              type="password"
            />
            <input
              v-else
              @change="setBlured('password2')"
              v-model="password2"
              name="password2"
              placeholder="Пароль"
              type="text"
            />
            <button v-on:click="switchVisibleSecondIcon">
              <v-icon>{{ show2 ? "mdi-eye" : "mdi-eye-off" }}</v-icon>
            </button>
          </div>
        </div>
        <span class="register__span_desc"
          >Пароль должен быть не менее 8 символов, включать заглавные и строчные
          буквы, цифры, спецсимволы</span
        >
        <v-btn color="secondary" v-on:click="generatePassword" x-small>
          Сгенерировать безопасный пароль
        </v-btn>
        <!-- password -->
      </div>

      <div v-else-if="e1 === 2">
        <!-- INN -->
        <div
          class="auth__input rounded auth__transition"
          :class="[
            !companyInn && firstBlured.find((item) => item === 'companyInn')
              ? 'auth__error_border mb-8'
              : 'mb-5',
          ]"
        >
          <label
            class="auth__error_text"
            v-if="
              !companyInn && firstBlured.find((item) => item === 'companyInn')
            "
            >Укажите ИНН компании</label
          >
          <input
            @change="setBlured('companyInn')"
            v-model="companyInn"
            name="companyInn"
            placeholder="Введите ИНН компании"
          />
        </div>
        <!-- INN -->
        <!-- KNN -->
        <div
          class="auth__input rounded auth__transition"
          :class="[
            !companyKpp && firstBlured.find((item) => item === 'companyKpp')
              ? 'auth__error_border mb-8'
              : 'mb-5',
          ]"
        >
          <label
            class="auth__error_text"
            v-if="
              !companyKpp && firstBlured.find((item) => item === 'companyKpp')
            "
            >Укажите КПП компании</label
          >
          <input
            @change="setBlured('companyKpp')"
            v-model="companyKpp"
            name="companyKpp"
            placeholder="Введите КПП компании"
          />
        </div>
        <!-- KNN -->
        <!-- phone -->
        <div
          class="auth__input rounded auth__transition"
          :class="[
            !phone && firstBlured.find((item) => item === 'phone')
              ? 'auth__error_border mb-8'
              : 'mb-5',
          ]"
        >
          <label
            class="auth__error_text"
            v-if="!phone && firstBlured.find((item) => item === 'phone')"
            >Укажите номер телефона</label
          >
          <input
            @change="setBlured('phone')"
            v-model="phone"
            name="phone"
            placeholder="Введите рабочий номер телефона"
          />
        </div>
        <!-- phone -->
      </div>

      <div v-else-if="e1 === 3">
        <!-- login -->
        <div
          class="auth__input rounded auth__transition"
          :class="[
            !fio_f && firstBlured.find((item) => item === 'fio_f')
              ? 'auth__error_border mb-8'
              : 'mb-5',
          ]"
        >
          <label
            class="auth__error_text"
            v-if="!username && firstBlured.find((item) => item === 'username')"
            >Укажите Логин</label
          >
          <input
            @change="setBlured('username')"
            v-model="username"
            name="username"
            placeholder="Введите Логин"
          />
        </div>
        <!-- login -->
        <!-- question -->
        <div
          class="auth__input rounded auth__transition"
          :class="[
            !secretQuestion &&
            firstBlured.find((item) => item === 'secretQuestion')
              ? 'auth__error_border mb-8'
              : 'mb-5',
          ]"
        >
          <label
            class="auth__error_text"
            v-if="
              !secretQuestion &&
              firstBlured.find((item) => item === 'secretQuestion')
            "
            >Укажите Секретный вопрос</label
          >
          <input
            @change="setBlured('secretQuestion')"
            v-model="secretQuestion"
            name="secretQuestion"
            placeholder="Введите секретный вопрос"
          />
        </div>
        <!-- question -->
        <!-- question -->
        <div
          class="auth__input rounded auth__transition"
          :class="[
            !secretAnswer && firstBlured.find((item) => item === 'secretAnswer')
              ? 'auth__error_border mb-8'
              : 'mb-5',
          ]"
        >
          <label
            class="auth__error_text"
            v-if="
              !secretAnswer &&
              firstBlured.find((item) => item === 'secretAnswer')
            "
            >Укажите ответ</label
          >
          <input
            @change="setBlured('secretAnswer')"
            v-model="secretAnswer"
            name="secretAnswer"
            placeholder="Введите ответ на секретный вопрос"
          />
        </div>
        <!-- question -->
      </div>

      <div v-else>
        <!-- login -->
        <v-alert outlined type="success" text v-if="isCompleted">
          Регистрация прошла успешно. Сейчас мы проверяем данные и предоставляем
          права доступа. Напишем, когда всё будет готово.
        </v-alert>
        <v-alert type="error" v-if="validationCodeError">
          Не удалось завершить регистрацию. Возможно, был введен неверный код
          подтверждения.
        </v-alert>
        <div
          class="auth__input rounded auth__transition"
          :class="[
            !confirmCode && firstBlured.find((item) => item === 'confirmCode')
              ? 'auth__error_border mb-8'
              : 'mb-5',
          ]"
        >
          <label
            class="auth__error_text"
            v-if="
              !confirmCode && firstBlured.find((item) => item === 'confirmCode')
            "
            >Укажите код подтверждения</label
          >
          <input
            @change="setBlured('confirmCode')"
            v-model="confirmCode"
            name="confirmCode"
            placeholder="Введите код подтверждения"
          />
        </div>
        <!-- login -->
      </div>
    </div>

    <button
      class="mt-10 auth__button rounded auth__transition"
      :class="[!isWaiting ? '' : 'auth__button_disabled']"
      v-on:click="isCompleted ? goToLogin() : nextStep()"
      :disabled="isWaiting"
    >
      {{ isCompleted ? "Завершить" : "Далее" }}
    </button>

    <button
      class="mt-3 auth__button register__back_button rounded auth__transition"
      v-on:click="goToLogin"
    >
      Вернуться ко входу
    </button>
  </Wrapper>
</template>

<script>
import { endpointUrl } from "../../app.config";
import axios from "axios";
import "../assets/styles/register.css";
import { STATIC_TO_SLIDE } from "../plugins/slider.js";
import Wrapper from "./Wrapper.vue";

export default {
  components: { Wrapper },
  props: {
    user: Object,
    appName: String,
    appLogo: String,
    appTitle: String
  },
  data() {
    return {
      slides: [],
      activeSlide: null,
      e1: 1,
      isWaiting: false,
      show1: false,
      show2: false,
      fio_f: "", // -- опустошить поля
      fio_i: "",
      fio_o: "",
      email: "",
      username: "",
      password: "",
      password2: "",
      companyInn: "",
      companyKpp: "",
      phone: "",
      secretQuestion: "",
      secretAnswer: "",
      confirmCode: "",
      validationCodeError: "",
      validationErrorEmail: false,
      validationErrorUsername: false,
      isCompleted: false,
      firstBlured: [],
      EMAIL_REGEXP:
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu,
      rules: {
        required: (value) => !!value || "Обязательно к заполнению",
        globalValidationPattern: (value) =>
          new RegExp(/^[\p{L}0-9 @.]+$/iu).test(value) ||
          "Заполнено некорректно",
        min: (v) => v.length >= 8 || "Не менее 8 символов",
        isUpperCase: (v) => /[A-Z]/.test(v) || "Не содержит заглавные буквы",
        isLowerCase: (v) => /[a-z]/.test(v) || "Не содержит строчные буквы",
        isNumbers: (v) => /[0-9]/.test(v) || "Не содержит цифры",
        isSpecChars: (v) => /[#?!@$%^&*-]/.test(v) || "Не содержит спецсимволы",
        email: (v) => /\S+@\S+\.\S+/.test(v) || "Некорректный адрес",
        passequal: (v) => v === this.password || "Не совпадает с паролем",
      },
    };
  },
  methods: {
    goToLogin() {
      this.$router.push("/login");
    },
    switchVisibleIcon() {
      this.show1 = !this.show1;
    },
    switchVisibleSecondIcon() {
      this.show2 = !this.show2;
    },
    validatePassword(value) {
      let rules = Object.values(this.rules);
      for (let validate of rules) {
        if (validate(value) !== true) {
          if (
            validate(value) === "Некорректный адрес" ||
            validate(value) === "Заполнено некорректно"
          ) {
            continue;
          }
          return validate(value);
        }
      }
    },
    setBlured(field) {
      console.log("blured called", field);
      if (this.firstBlured.length) {
        if (this.firstBlured.find((item) => item === field)) {
          return;
        } else {
          this.firstBlured.push(field);
        }
      } else {
        this.firstBlured.push(field);
      }
    },
    generatePassword() {
      function password_generator(len) {
        var length = len ? len : 10;
        var string = "abcdefghijklmnopqrstuvwxyz";
        var numeric = "0123456789";
        var punctuation = "#?!@$%^&*-";
        var password = "";
        var character = "";
        while (password.length < length) {
          let entity1 = Math.ceil(
            string.length * Math.random() * Math.random()
          );
          let entity2 = Math.ceil(
            numeric.length * Math.random() * Math.random()
          );
          let entity3 = Math.ceil(
            punctuation.length * Math.random() * Math.random()
          );
          let hold = string.charAt(entity1);
          hold = password.length % 2 == 0 ? hold.toUpperCase() : hold;
          character += hold;
          character += numeric.charAt(entity2);
          character += punctuation.charAt(entity3);
          password = character;
        }
        password = password
          .split("")
          .sort(function () {
            return 0.5 - Math.random();
          })
          .join("");
        return password.substr(0, len);
      }
      this.password = password_generator(10);
      this.show1 = true;
    },
    nextStep(e) {
      if (e) {
        e.preventDefault();
      }
      let self = this;
      if (self.isWaiting) return;
      if (self.e1 === 1) {
        if (
          this.fio_f &&
          this.fio_i &&
          this.fio_o &&
          this.email &&
          this.EMAIL_REGEXP.test(this.email) &&
          !this.validatePassword(this.password) &&
          this.rules.passequal(this.password2)
        ) {
          self.isWaiting = true;
          self.validationErrorEmail = false;
          axios({
            url: endpointUrl + "validateLoginOrEmail",
            method: "POST",
            data: {
              loginemail: self.email,
            },
          }).then((res) => {
            self.isWaiting = false;
            if (res && res.data && res.data.status === "validated") {
              self.e1 = self.e1 + 1;
            } else {
              self.validationErrorEmail = true;
            }
          });
        }

        // }
      } else if (self.e1 === 2) {
        if (this.phone && this.companyKpp && this.companyInn) {
          // if (self.$refs.regform2.validate()) {
          self.e1 = self.e1 + 1;
        }
      } else if (self.e1 === 3) {
        // if (self.$refs.regform3.validate()) {
        if (self.secretAnswer && self.secretQuestion && self.username) {
          self.isWaiting = true;
          self.validationErrorUsername = false;
          axios({
            url: endpointUrl + "validateLoginOrEmail",
            method: "POST",
            data: {
              loginemail: self.username,
            },
          }).then((res) => {
            self.isWaiting = false;
            if (res && res.data && res.data.status === "validated") {
              self.isWaiting = true;
              axios({
                url: endpointUrl + "sendValidationCodeByEmail",
                method: "POST",
                data: {
                  email: self.email,
                },
              }).then((res) => {
                self.isWaiting = false;
                if (res && res.data && res.data.status === "ok") {
                  self.e1 = self.e1 + 1;
                } else {
                  alert(
                    "Возникла ошибка. Попробуйте выполнить операцию позднее."
                  );
                }
              });
            } else {
              self.isWaiting = false;
              self.validationErrorUsername = true;
            }
          });
        }
      } else if (self.e1 === 4) {
        // if (self.$refs.regform4.validate()) {
        if (self.confirmCode) {
          self.isWaiting = true;
          self.validationCodeError = false;

          axios({
            url: endpointUrl + "registerNewAccount",
            method: "POST",
            data: {
              userData: {
                fio_f: self.fio_f,
                fio_i: self.fio_i,
                fio_o: self.fio_o,
                email: self.email,
                username: self.username,
                password: self.password,
                companyInn: self.companyInn,
                companyKpp: self.companyKpp,
                phone: self.phone,
                secretQuestion: self.secretQuestion,
                secretAnswer: self.secretAnswer,
                confirmCode: self.confirmCode,
              },
            },
          }).then((res) => {
            self.isWaiting = false;
            if (res && res.data && res.data.status === "ok") {
              self.isCompleted = true;
            } else {
              self.validationCodeError = true;
            }
          });
        }
      }
    },
  },
  beforeMount() {
    this.slides =
      STATIC_TO_SLIDE && STATIC_TO_SLIDE.length ? [...STATIC_TO_SLIDE] : [];
    this.activeSlide = this.slides.length && this.slides[0];

    setInterval(() => {
      let currentIndex = this.slides.findIndex(
        (item) => item === this.activeSlide
      );
      if (!this.slides[currentIndex + 1]) {
        this.activeSlide = this.slides[0];
      } else {
        this.activeSlide = this.slides[currentIndex + 1];
      }
    }, 10000);
  },
};
</script>
