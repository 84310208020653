<template>
  <div class="home">
    <Register :appName="appName" :appLogo="appLogo" :appTitle="appTitle" />
  </div>
</template>

<script>
// @ is an alias to /src
import Register from '@/components/Register.vue'

export default {
  props:['appName', 'appLogo', 'appTitle'],
  name: 'RegisterView',
  components: {
    Register
  }
}
</script>
