<template>
  <Wrapper :appName="appName" :appLogo="appLogo" :title="'ЭксплоИТ'" :appTitle="appTitle">

    <h class="h-1">Восстановление пароля</h>

    <v-alert
      class="box-alert"
      prominent
      text
      type="error"
      v-show="notValidated"
    >
      {{errorText}}
    </v-alert>

    <v-alert
        type="success"
        class="box-alert"
        text
        v-if="isCompleted">
      Новый пароль отправлен на почту. Вы можете войти в аккаунт.
    </v-alert>


    <div class="auth__input mb-5 rounded">
      <p>E-mail или логин</p>
      <input
        v-model="loginemail"
        name="loginemail"
        type="test"
        :class="[step !== 1 ? 'auth__button_disabled_text' : '']"
        :disabled="step !== 1"
      />
    </div>

    <button
      class="auth__button auth__transition mb-3"
      v-on:click="pefrormRestore"
    >
      Далее
    </button>
  </Wrapper>
</template>

<script>
import { endpointUrl } from "../../app.config";
import axios from "axios";
import Wrapper from "./Wrapper.vue";

export default {
  components: {
    Wrapper,
  },
  props: {
    user: Object,
    appName: String,
    appLogo: String,
    appTitle: String
  },
  name: "Restore",
  data: () => ({
    loginemail: "",
    step: 1,
    notValidated: false,
    isLoginInProcess: false,
    show1: false,
    show2: false,
    isCompleted: false,
    errorText: ''
  }),

  computed: {
  },

  created() {
  },

  methods: {
    goToLogin(e) {
      if (e) e.preventDefault();
      this.$router.push("/login");
    },
    pefrormRestore(e) {
      if (e) e.preventDefault();
      let self = this;

      if (self.step === 1 && !self.isLoginInProcess && self.loginemail) {
        self.notValidated = false;
        self.isLoginInProcess = true;
        self.errorText = '';
        axios({
          url: endpointUrl + "restoreByLoginOrEmail",
          method: "POST",
          data: {
            loginemail: self.loginemail,
          },
        }).then((res) => {
          self.isLoginInProcess = false;
          if (res && res.data && res.data.status === "ok") {
            self.step++;
            self.isCompleted = true;
          } else {
            self.notValidated = true;
            if(res && res.data && res.data.error) {
              self.errorText = res.data.error;
            } else {
              self.errorText = 'Системная ошибка. Обратитесь к администратору.';
            }
          }
        });
      }

      if (self.step === 2) {
        this.$router.push("/login");
      }
    },
  },
};
</script>
