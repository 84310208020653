<template>
  <div class="vars__options auth__container d-flex justify-center">
    <div
      class="auth__wrapper d-flex flex-row justify-center align-center"
      :class="wrapperStyles ? wrapperStyles : ''"
    >
      <div
        class="d-flex flex-column justify-center form-container align-center"
        :class="[authFormStyles ? authFormStyles : '']"
      >
        <div v-if="appLogo" v-html="appLogo"></div>
        <img
          v-if="!appLogo && appName && appName === 'Explo-IT'"
          :class="[logoStyles !== '' ? logoStyles : 'mb-10']"
          src="../assets/LogoIT.png"
          height="62" width="62"
        />
        <h1 v-else-if="!appLogo && appName" class="auth__logo_title mb-3">{{ appName }}</h1>
        <img
          v-else-if="!appLogo"
          :class="[logoStyles !== '' ? logoStyles : 'mb-10']"
          src="../assets/LogoIT.png"
          height="62" width="62"
        />
        <!-- Расскоментировать для ммк и закомментировать картинку выше -->

<!--        <h3 v-if="title" class="title">{{ title }}</h3>-->

        <h3 v-if="!appTitle" class="title">{{ title }}</h3>
        <h3 v-else-if="!!appTitle" class="title">{{ appTitle }}</h3>



        <slot :class="[slotStyles ? slotStyles : '']"></slot>
      </div>
    </div>

  </div>
</template>

<script>
import "../assets/styles/main.css";
import "../assets/styles/register.css";

export default {
  components: {

  },
  props: [
    "title",
    "logoStyles",
    "wrapperStyles",
    "authFormStyles",
    "slotStyles",
    "appName",
    "appLogo",
    "appTitle"
  ],
  data() {
    return {
      slides: [],
      activeSlide: null,
    };
  },
};
</script>
