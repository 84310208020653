<template>
  <div class="home">
    <Restore :appName="appName" :appLogo="appLogo" :appTitle="appTitle" />
  </div>
</template>

<script>
// @ is an alias to /src
import Restore from '@/components/Restore.vue'

export default {
  props:['appName', 'appLogo', 'appTitle' ],
  name: 'RestoreView',
  components: {
    Restore
  }
}
</script>
