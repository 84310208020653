<template>
  <Wrapper :appName="appName" :title="'ЭксплоИТ'" :appLogo="appLogo" :appTitle="appTitle">

    <v-alert
      text
      type="info"
      v-show="!hideInitBanner"
      class="box-alert"
    >
      Для обеспечения безопасности аккаунта необходимо изменить пароль.
    </v-alert>

    <v-alert
      prominent
      text
      type="error"
      v-show="isBlocked || notValidated"
      class="box-alert"
    >
      Невозможно изменить пароль сейчас. Пожалуйста, обратитесь в техническую поддержку.
    </v-alert>

    <v-alert
      text
      color="error"
      class="box-alert"
      v-show="passwordError"
    >
      <div class="mb-2"><b>Пароль не изменён</b></div>
      <ul class="ul-none">
        <li v-for="(item, index) in reasons" :key="index">{{item}}</li>
      </ul>

      <v-alert
          text
          class="box-alert mt-2"
          type="error"
          v-show="passwordsMatchError && newpassword !== newpassword2"
      >
        Пароли не совпадают
      </v-alert>
      
    </v-alert>

    <v-alert
        type="success"
        class="box-alert"
        text
        v-if="isCompleted" >
      Пароль успешно изменен. Вы можете войти в аккаунт.
    </v-alert>

    <form
      class="auth__form d-flex flex-column"
      v-on:submit="
        ($event) => {
          $event.preventDefault();
          pefrormChangePassword($event, true);
        }
      "
      @keyup.enter.native="
        ($event) => {
          $event.preventDefault();
          pefrormChangePassword($event, true);
        }
      "
    >

    
    <div class="password-div auth__input auth__two_params rounded d-flex flex-column" v-if="!isBlocked && !isCompleted">
      <p>Новый пароль</p>
      <div class="password-div">
        <input
            v-model="newpassword"
            name="newpassword"
            v-if="!show1"
            type="password"
        />
        <input
            v-model="newpassword"
            name="newpassword"
            v-else
            type="text"
        />
        <button v-on:click="switchVisibleIcon1" class="button" type="button">
          <v-icon>{{ show1 ? "mdi-eye" : "mdi-eye-off" }}</v-icon>
        </button>
      </div>

    </div>

    <div class="auth__input auth__two_params rounded d-flex flex-column" v-if="!isBlocked && !isCompleted" >
      <p>Подтверждение пароля</p>
      <div class="password-div">
        <input
              v-model="newpassword2"
              name="newpassword2"
              v-if="!show2"
              type="password"
            />
            <input
              v-model="newpassword2"
              name="newpassword2"
              v-else
              type="text"
            />
            <button v-on:click="switchVisibleIcon2" class="button" type="button">
              <v-icon>{{ show2 ? "mdi-eye" : "mdi-eye-off" }}</v-icon>
            </button>
      </div>
    </div>

    <button
      class="auth__button auth__transition"
      v-on:click="pefrormChangePassword"
      :disabled="isLoginInProcess || !newpassword || (!password && !preAuthPassword)"
      v-show="!isBlocked && !isCompleted"
    >
      Изменить пароль
    </button>

    <button
    class="auth__button auth__transition"
    v-on:click="goToLogin"
    v-show="isCompleted"
  >
    Перейти ко входу в систему
  </button>
</form>
  </Wrapper>
</template>

<script>
import { endpointUrl } from "../../app.config";
import axios from "axios";
import Wrapper from "./Wrapper.vue";

export default {
  components: {
    Wrapper,
  },
  props: {
    user: Object,
    appName: String,
    appLogo: String,
    appTitle: String
  },
  name: "ChangePassword",
  data: () => ({
    loginemail: "",
    notValidated: false,
    isBlocked: false,
    passwordError: false,
    reason: "",
    reasons: [],
    preAuthPassword: false,
    newpassword: "",
    newpassword2: "",
    show1: false,
    show2: false,
    isCompleted: false,
    password: "",
    hideInitBanner: false,
    isLoginInProcess: false,
    passwordsMatchError: false
  }),

  computed: {
  },

  created() {
    let self = this;
    if (
      this.$route.params.changePasswordUsername &&
      this.$route.params.changePasswordTmpPassword
    ) {
      self.loginemail = this.$route.params.changePasswordUsername;
      self.password = this.$route.params.changePasswordTmpPassword;
      self.preAuthPassword = true;
    } else {
        this.$router.push("/login");
    }
  },

  methods: {
    goToLogin(e) {
      if (e) e.preventDefault();
      this.$router.push("/login");
    },
    switchVisibleIcon1(e) {
      if (e) e.preventDefault();
      this.show1 = !this.show1;
    },
    switchVisibleIcon2(e) {
      if (e) e.preventDefault();
      this.show2 = !this.show2;
    },
    pefrormChangePassword(e) {
      if (e) e.preventDefault();
      let self = this;

      self.passwordsMatchError = false;

      if(self.newpassword !== self.newpassword2) {
        self.passwordsMatchError = true;
        return;
      }

      if (!self.isLoginInProcess && self.loginemail && self.newpassword && self.newpassword === self.newpassword2) {
        self.notValidated = false;
        self.isLoginInProcess = true;
        self.passwordError = false;
        self.reason = "";
        self.hideInitBanner = true;
        
        axios({
          url: endpointUrl + "changepassword",
          method: "POST",
          data: {
            username: self.loginemail,
            password: self.password,
            new_password: self.newpassword
          },
        }).then((res) => {
          self.isLoginInProcess = false;
          self.newpassword = "";
          self.newpassword2 = "";
          if (res && res.data && res.data.status === "ok") {
            self.isCompleted = true;
          } else if (res && res.data && res.data.status === "Access denied") {
            if (res.data.isBlocked) {
                self.isBlocked = true;
            } else {
                self.notValidated = true;
            }
          } else if (res && res.data && res.data.status === "error") {
            self.passwordError = true;
            self.reason = res.data.reason;
            self.reasons = res.data.reason ? res.data.reason.split('; ') : [];
          }
        });
      }
      
    },
  },
};
</script>
