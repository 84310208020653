<template>
  <Wrapper :appName="appName" :appLogo="appLogo" :title="isLkLogin ? 'Вход в личный кабинет' : 'ЭксплоИТ'" :appTitle="appTitle">
    <v-alert
      icon="mdi-alert-octagon"
      prominent
      text
      type="error"
      v-if="isLoginFailed"
      class="mx-auto justify-center box-alert"
    >
      {{ loginFailedText }} <br> {{ loginFailedText2 }}
    </v-alert>

    <v-alert
      icon="mdi-alert-octagon"
      prominent
      text
      type="info"
      v-show="req2fa && !req2fa_is_no_email"
      class="box-alert"
    >
      Необходимо подтвердить вход кодом из почты. Мы его уже выслали. Введите
      код в поле ниже. <br /><br />

      <span
        v-on:click="disableValidation('email')"
        style="text-decoration: underline; cursor: pointer"
        >Авторизоваться под другим пользователем</span
      >
    </v-alert>

    <form
      class="auth__form d-flex flex-column"
      v-on:submit="
        ($event) => {
          $event.preventDefault();
          accessToAuth && pefrormLogin($event, true);
        }
      "
      @keyup.enter.native="
        ($event) => {
          $event.preventDefault();
          accessToAuth && pefrormLogin($event, true);
        }
      "
    >
      <div
        class="auth__input"
        :class="req2fa && !req2fa_is_no_email ? 'disabled' : ''"
      >
        <p>Логин</p>
        <input
          v-model="username"
          name="username"
          :disabled="req2fa ? req2fa && !req2fa_is_no_email : false"
        />
      </div>

      <div
        class="auth__input auth__two_params d-flex flex-column"
        :class="req2fa && !req2fa_is_no_email ? 'disabled' : ''"
      >
        <p>Пароль</p>
        <div class="password-div">
          <input
              :disabled="req2fa ? req2fa && !req2fa_is_no_email : false"
              v-model="password"
              name="password"
              v-if="!show1"
              type="password"
          />
          <input
              :disabled="req2fa ? req2fa && !req2fa_is_no_email : false"
              v-model="password"
              name="password"
              v-else
              placeholder="Пароль"
              type="text"
          />
          <div v-on:click="switchVisibleIcon" class="button">
            <v-icon>{{ show1 ? "mdi-eye" : "mdi-eye-off" }}</v-icon>
          </div>
        </div>
      </div>

      <div
        class="auth__input"
        v-show="req2fa && !req2fa_is_no_email"
      >
        <p>Код подтверждения</p>
        <input
          v-model="req2fa_user_input"
          name="username"
        />
      </div>

      <button
        class="auth__button auth__transition"
        :class="[
          accessToAuth ? '' : 'auth__button_disabled',
          isClickedFromEnter ? 'auth__clicked' : '',
        ]"
        v-on:click="
          {
            $event.preventDefault();
            pefrormLogin($event, true);
          }
        "
        :disabled="accessToAuth === false ? true : false"
      >
        Войти
      </button>

      <div
          v-on:click="goToRestore"
          class="auth__transition auth__open_link"
      >
        <span>Забыли пароль?</span>
      </div>
    </form>

  </Wrapper>
</template>

<script>
import "../assets/styles/main.css";
import { endpointUrl, successUrl, ldapOptions } from "../../app.config";
import axios from "axios";
import Wrapper from "./Wrapper.vue";

export default {
  components: { Wrapper },
  props: {
    user: Object,
    appName: String,
    appLogo: String,
    appTitle: String
  },
  name: "Auth",
  data: () => ({
    fio_f: "",
    fio_i: "",
    fio_o: "",
    email: "",
    username: "",
    password: "",
    password2: "",
    isLoginFailed: false,
    loginFailedText: "",
    loginFailedText1: "",
    loginFailedText2: "",
    isLoginInProcess: false,
    isClickedFromEnter: false,
    token: "",
    req2fa_user_input: "",
    req2fa: false,
    req2fa_is_no_email: false,
    showRestoreButton: false,
    enableLogin: false,
    queryurl: false,
    isLkLogin:false,
    show1: false,
    accessToAuth: true,

    // longPooling emailCode
    isStartedPool: false,
    isSendedEmailCode: false,
    mailId: null,
    mailResponseError: null,
    isHasErrorFromPoolReq: false,
  }),

  watch: {
    password: {
      deep: true,
      handler() {
        this.setAccessAuth();
      },
    },
    username: {
      deep: true,
      handler() {
        this.setAccessAuth();
      },
    },
    req2fa_user_input: {
      deep: true,
      handler() {
        this.setAccessAuth();
      },
    },
    // isStartedPool: {
    //   deep: true,
    //   handler() {
    //     if (this.isStartedPool) {

    //     }
    //   },
    // },
  },
  computed: {
    disableLoginButton() {
      return (
        this.token || this.isLoginInProcess || !this.username || !this.password
      );
    },
    displayLdapLoginButton() {
      return !!ldapOptions.enableStrategy;
    },
  },

  created() {
    if (this.$route.query.url) {
      this.queryurl = decodeURI(this.$route.query.url);
      if (this.queryurl && this.queryurl.includes('lk') && !this.queryurl.includes('lk.')) {
       this.isLkLogin = true;
      }
    }
    if (new URL(location.href).searchParams.get("url")) {
      this.queryurl = decodeURI(new URL(location.href).searchParams.get("url"));
      if (this.queryurl && this.queryurl.includes('lk') && !this.queryurl.includes('lk.')) {
       this.isLkLogin = true;
      }
    }

    setInterval(() => {
      if (this.isStartedPool) {
        if (!this.mailId) {
          return;
        }
        axios
          .get(
            endpointUrl + "checkMailValidation" + "?newEmailId=" + this.mailId
          )
          .catch((err) => {
            console.error(err);
            if (err?.response?.data) {
              console.log("RESPONSE DATA:", err?.response?.data);
            }
          })
          .then((res) => {
            if (res.data.status) {
              console.log("CODE IS SENDED");
              this.isStartedPool = false;
            } else {
              this.mailResponseError =
                "Код не был отправлен. Пожалуйста, попробуйте авторизоваться позже или обратитесь в тех. поддержку";
              this.isHasErrorFromPoolReq = true;
            }
          });
      }
    }, 7000);
  },

  methods: {
    goToRegister() {
      this.$router.push("/register");
    },
    goToRestore() {
      this.$router.push("/restore");
    },
    switchVisibleIcon() {
      console.log("called switchVisibleIcon");
      this.show1 = !this.show1;
    },
    setFromChildToParent(e, field) {
      if (field) {
        this[field] = e;
        console.log("this[field]", this[field]);
      }
    },
    setAccessAuth() {
      if (
        this.password &&
        this.password !== "" &&
        this.username &&
        this.username !== ""
      ) {
        this.accessToAuth = true;
      } else {
        this.accessToAuth = false;
      }
    },
    pefrormLogin(e, isFromButtonEvent) {
      if (isFromButtonEvent === true) {
        this.isClickedFromEnter = true;
        setTimeout(() => {
          this.isClickedFromEnter = false;
        }, 300);
      }
      if (e) {
        e.preventDefault();
      }
      let self = this;
      let mid = localStorage.getItem("mid");
      if (!mid) {
        mid = parseInt(Math.random() * 10000000);
        localStorage.setItem("mid", mid);
      }
      if (!self.isLoginInProcess && self.username && self.password) {
        self.token = "";
        self.isLoginInProcess = true;
        self.isLoginFailed = false;
        self.req2fa = false;
        self.req2fa_is_no_email = false;
        self.showRestoreButton = false;
        self.enableLogin = false;
        let needtmptoken = !!self.queryurl;
        let loginPromise = axios({
          url: endpointUrl + "auth",
          method: "POST",
          data: {
            username: self.username,
            password: self.password,
            lte: self.req2fa_user_input,
            lt: localStorage.getItem("lt"),
            mid: localStorage.getItem("mid"),
            needtmptoken: needtmptoken,
            client: "web"
          },
        });

        loginPromise
          .then((res) => {
            self.isLoginInProcess = false;
            if (res && res.data && res.data.status === "Access granted") {
              //self.token = res.data.token;

              if (res.data.scope === "api") {
                console.log("API TOKEN");
                console.log(res.data.token);

                self.isLoginFailed = true;
                self.loginFailedText =
                  "Вход в веб-интерфейс для указанного пользователя невозможен.";
              } else {
                if (self.req2fa_user_input) {
                  localStorage.setItem("lt", self.req2fa_user_input);
                }

                console.log("ACCESS TOKEN");
                console.log(res.data.token);

                let setCookie = function (name, value, days) {
                  let expires = "";
                  if (days) {
                    let date = new Date();
                    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
                    expires = "; expires=" + date.toUTCString();
                  }
                  document.cookie =
                    name + "=" + (value || "") + expires + "; path=/";
                };
                setCookie("authtoken", res.data.token);
                if (self.queryurl && res.data.shorttoken) {
                  // let url = new URL(self.queryurl);
                  // if (url.searchParams.has("doc")) {
                  //   window.location =
                  //     url.origin +
                  //     "?shorttoken=" +
                  //     res.data.shorttoken +
                  //     "&doc=" +
                  //     url.searchParams.get("doc");
                  //   return;
                  // }
                  if(self.isLkLogin) {
                    window.location = self.queryurl + "?mvctoken=" + res.data.shorttoken;
                  } else {
                    window.location = self.queryurl + "?shorttoken=" + res.data.shorttoken;
                  }
                  

                  // if (self.queryurl.indexOf("doc") !== -1) {
                  //   // console.log(
                  //   //   self.queryurl + "&shorttoken=" + res.data.shorttoken
                  //   // );
                  //   // alert(self.queryurl + "&shorttoken=" + res.data.shorttoken);
                  //   window.location =
                  //     self.queryurl + "&shorttoken=" + res.data.shorttoken;
                  // } else {
                  //   // console.log(
                  //   //   self.queryurl + "?shorttoken=" + res.data.shorttoken
                  //   // );
                  //   // alert(self.queryurl + "?shorttoken=" + res.data.shorttoken);
                  //   window.location =
                  //     self.queryurl + "?shorttoken=" + res.data.shorttoken;
                  // }
                } else {
                  window.location = successUrl;
                }
              }
            } else if (res.data.status === "needtorestore") {
              this.$router.push({
                name: "ChangePasswordView",
                params: {
                  changePasswordUsername: self.username,
                  changePasswordTmpPassword: self.password,
                  changePasswordReason: res.data.reason
                },
              });
            } else if (res.data.status === "2fa_code_req") {
              self.req2fa = true;
              self.isStartedPool = true;
              if (res.data.options.newEmailId) {
                self.mailId = res.data.options.newEmailId;
              }
            } else if (res.data.status === "2fa_wrong_code") {
              self.req2fa = true;
              self.isLoginFailed = true;
              self.loginFailedText = "Ошибка. Неверный код.";
            } else if (res.data.status === "2fa_no_email") {
              self.req2fa = true;

              self.req2fa_is_no_email = true;
            } else if (res.data.status === "Access denied") {
              self.isLoginFailed = true;
              if (res.data.isBlocked) {
                self.loginFailedText =
                    "Пользователь заблокирован.";
                self.loginFailedText2 = res.data.isBlockedBySystem ?
                    "Обратитесь в службу поддержки, либо воспользуйтесь функцией восстановления доступа." : "Обратитесь в службу поддержки.";
              } else if (res.data.wrongIp) {
                self.loginFailedText =
                  "Вход с вашего IP-адреса для этого пользователя невозможен.";
              } else if (res.data.noRole) {
                self.loginFailedText =
                  "Для учетной записи еще не присвоена роль доступа. Пожалуйста, ожидайте подтверждения учетной записи администратором.";
              } else if (res.data.noEmail) {
                self.loginFailedText =
                  "Невозможно авторизоваться. Не указан email сотрудника. Обратитесь в службу технической поддержки.";
              } else if (res.data.isUserFound) {
                self.loginFailedText =
                    "Введён неверный пароль. ";
                self.loginFailedText2 =
                    "Попробуйте ещё раз.";
                  self.showRestoreButton = true;
              } else {
                self.loginFailedText = "Пользователь "+self.username+" не найден.";
              }
            } else {
              self.isLoginFailed = true;
              self.loginFailedText = "Ошибка сервиса аутентификации";
            }
          })
          .finally(() => {
            if (this.isLoginFailed || this.loginFailedText !== "") {
              this.accessToAuth = false;
            }
          });
      }
    },

    disableValidation(type) {
      type = type || "email";

      switch (type) {
        case "email":
          if (this.req2fa && !this.req2fa_is_no_email) {
            this.req2fa = false;
            this.username = "";
            this.password = "";
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
