<template>
  <div class="home">
    <ChangePassword :appName="appName" :appLogo="appLogo" :appTitle="appTitle" />
  </div>
</template>

<script>
// @ is an alias to /src
import ChangePassword from '@/components/ChangePassword.vue'

export default {
  props:['appName', 'appLogo', 'appTitle'],
  name: 'ChangePasswordView',
  components: {
    ChangePassword
  }
}
</script>
